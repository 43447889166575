import React from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaLinkedin } from 'react-icons/fa'; // React Icons
import styles from '../assets/scss/componnet/footer.module.scss'; // Create this SCSS file
import { services } from '../fetures/config';

export default function Footer() {
  // Data for the footer
  const footerData = {
    logo: {
      src: '/logo.png', // Replace with actual logo path
      description: "Shaping Tomorrow's Innovation with AI and Data-Driven Solutions.",
      tagline: "Let's Build It Together",
    },
    socialLinks: [
      { icon: <FaFacebookF />, url: 'https://facebook.com' },
      { icon: <FaInstagram />, url: 'https://instagram.com' },
      { icon: <FaTwitter />, url: 'https://twitter.com' },
      { icon: <FaLinkedin />, url: 'https://linkedin.com' },
    ],
    quickLinks: [
      { label: 'Home', url: '/' },
      { label: 'Services', url: '/our-services' },
      { label: 'About', url: '/about-us' },
    ],
    contact: {
      phone: '+91 6294467292',
      email: 'support@one08.tech',
      address: 'Lucknow, Uttar Pradesh 226401',
    },
    copyright: '© 2024 one08. All rights reserved.',
  };

  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.container}>
          {/* Logo and Description */}
          <div className={styles.footerSection}>
            <div className={styles.logo}>
              {/* <span>One08.tech</span> */}
              <img  src="/assets/logo.webp" alt="" />
            </div>
            <p>{footerData.logo.description}</p>
            <strong>{footerData.logo.tagline}</strong>

            {/* Social Media Links */}
            <div className={styles.socialLinks}>
              {footerData.socialLinks.map((social, index) => (
                <a href={social.url} key={index} target="_blank" rel="noopener noreferrer">
                  {social.icon}
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div className={styles.footerSection}>
            <h3>Quick Links</h3>
            <ul>
              {footerData.quickLinks.map((link, index) => (
                <li key={index}>
                  <a href={link.url}>{link.label}</a>
                </li>
              ))}
            </ul>
          </div>
          

          {/* Services */}
          <div className={styles.footerSection}>
            <h3>Services</h3>
            <ul>
              {services.map((service, index) => (
                <li key={index}>
                  <a href={`/our-services/${service.label.toLowerCase().replace(/\s+/g, '-')}`}>{service.label}</a>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div className={styles.footerSection}>
            <h3>Contact</h3>
            <ul>
              <li><a href={`tel:${footerData.contact.phone}`}>{footerData.contact.phone}</a></li>
              <li><a href={`mailto:${footerData.contact.email}`}>{footerData.contact.email}</a></li>
              <li>{footerData.contact.address}</li>
            </ul>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className={styles.footerBottom}>
        <p>© 2024 one08. All rights reserved.</p>
      </div>
    </footer>
  );
}
